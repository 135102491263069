import React , {useEffect, useState} from 'react';
import img1 from '../../assets/images/layout/staking.png'
import './banner.scss'

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';


function Banner04(props) {

    useEffect(()=>{
        // The End Of Year Date To Countdown To

let countDownDate = new Date("Jan 1, 2025 23:59:59").getTime();

let counter = setInterval(() => {
  // Get Date Now
  let dateNow = new Date().getTime();

  // Find The Difference Between The Time Now And The Countdown Date
  let dateDiff = countDownDate - dateNow;

  let days = Math.floor(dateDiff / (1000 * 60 * 60 * 24));

  let hours = Math.floor((dateDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

  let minutes = Math.floor((dateDiff % (1000 * 60 * 60)) / (1000 * 60));

  let seconds = Math.floor((dateDiff % (1000 * 60)) / 1000);

  document.querySelector(".days").innerHTML =
    days < 100 && days > 10 ? `0${days}` : days < 10 ? `00${days}` : days;
  document.querySelector(".hours").innerHTML = hours < 10 ? `0${hours}` : hours;
  document.querySelector(".minutes").innerHTML =
    minutes < 10 ? `0${minutes}` : minutes;
  document.querySelector(".seconds").innerHTML =
    seconds < 10 ? `0${seconds}` : seconds;

  if (dateDiff <= 0) {
    clearInterval(counter);
  }
}, 1000);

    });
  
    const [dataBlock] = useState(
        {
            title: 'Crypto Staking',
            desc : 'CetoEX Staking allows you to earn a passive income by holding and staking your cryptocurrency. You can receive regular rewards for participating in the network and securing it. Staking encourages long-term holding of the cryptocurrency instead of selling it. This can reduce selling pressure in the market and contribute to price stability.',
            title2: 'COMMING IN'
        }
    );
    return (
        <section className="banner">
                <div className="container">
                    <div className="row">
                    <div className="col-xl-6 col-md-12">
                        <div className="banner__content">
                        <h2 className="title">{dataBlock.title}</h2>
                        <p className="fs-20 desc">
                            {dataBlock.desc}
                        </p>
                       
                       
                        <div className="partner">
                        <h6>{dataBlock.title2}</h6>
                            <div className="partner__list">

                            <div className="containerTimer">
  <div>
    DAYS
    <span className="days" />
  </div>
  <div>
    HRS
    <span className="hours" />
  </div>
  <div>
    MIN
    <span className="minutes" />
  </div>
  <div>
    SEC
    <span className="seconds" />
  </div>
</div>
                 
                            </div>
                        </div>
                       
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-12">
                        <div className="banner__image">
                        <img src={img1} alt="staking"  style={{width:"80%"}}/>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
    );
}

export default Banner04;