import React , {useState} from 'react';
import 'react-modal-video/scss/modal-video.scss' ;
import Trading from '../components/trading/Trading';

import {Link} from 'react-router-dom';
import Sale01 from '../components/sale/Sale01';
import PageTitle from '../components/pagetitle';
import dataTrading from '../assets/fake-data/data-trading';
import { Helmet } from 'react-helmet';


function Refferal() {

  

    return (
        <div>
                                                                  
<Helmet>
        <title> Join Cetoex Referral Program | Earn Rewards for Sharing | Cetoex</title>
        <meta name="description" content="Become a Part of the Cetoex Referral Program. Invite Friends and Earn Rewards for Every Successful Referral, Empowering You and Your Network."/>
        <meta name="keywords" content="crypto market, crypto deposit history, cetoex, exchange, top ranking coin, top volume, newly listed, top gainers, top losers, cetoex exchange, spot, trading, crypto"/>
        <meta name="author" content="Cetoex"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        
        {/* Open Graph (OG) tags for sharing on social media */}
        <meta property="og:title" content=" Join Cetoex Referral Program | Earn Rewards for Sharing | Cetoex"/>
        <meta property="og:description" content="Become a Part of the Cetoex Referral Program. Invite Friends and Earn Rewards for Every Successful Referral, Empowering You and Your Network."/>
        
        {/* Twitter card tags for sharing on Twitter */}
        <meta name="twitter:card" content="cetoex"/>
        <meta name="twitter:title" content=" Join Cetoex Referral Program | Earn Rewards for Sharing | Cetoex"/>
        <meta name="twitter:description" content="Become a Part of the Cetoex Referral Program. Invite Friends and Earn Rewards for Every Successful Referral, Empowering You and Your Network."/>
        <meta name="twitter:image" content="https://pbs.twimg.com/profile_images/1556224061248008192/KQTT5Qk3_400x400.jpg"/>
        
      </Helmet>
          

            <section className="join">
                <div className="container">
                    <div className="row">
                    <div className="col-xl-12 col-md-12">
                        <div className="blog-main">
                       
                       
                        <div className="content">
                            <h5>What is the Referral Program?</h5>
                            <p>
                            CetoEX exchange referral program is a specific type of referral program that is typically offered by us. In this program, users are encouraged to refer new users to the exchange in exchange for a reward of <b>400 Million BABYDOGE</b> (BABYDOGE), which is a popular cryptocurrency.<br/>The basic idea behind Cetoex exchange referral program is similar to other referral programs: We wants to attract new users, and they believe that their existing users are the best people to help spread the word. By offering a reward of  30 Million BABYDOGE, the exchange hopes to motivate users to refer their friends, family, or colleagues to the exchange.
                            </p>

                           <br/>

                          
                           
                            
                        </div>

                    

                        </div>
                    </div>
                   
                    </div>
                </div>
                <Trading data={dataTrading} />
            </section>
          
            <Sale01 />
            
        </div>
    );
}

export default Refferal;