import React , {useState} from 'react';
import PropTypes from 'prop-types';
import img1 from '../../assets/images/icon/Cloud.png'
import img2 from '../../assets/images/icon/Wallet.png'
import img3 from '../../assets/images/icon/Mining.png'

import img from '../../assets/images/referral.jpg'

Trading.propTypes = {
    data: PropTypes.array
};

function Trading(props) {

   
const data = [
    {
        id: 1,
        icon: img1,
        step: 'Bonus 1',
        title: 'Register Airdrop',
        text: 'Get FREE 100 Million BABYDOGE on register your account & verify KYC Document with CetoEX .',
    },
    {
        id: 2,
        icon: img2,
        step: 'Bonus 2',
        title: 'First Invesment Airdrop',
        text: 'Get FREE 300 Million BABYDOGE on first investment in crypto with CetoEX.',
    },
    {
        id: 3,
        icon: img3,
        step: 'Bonus 3',
        title: 'Referral Airdrop',
        text: 'Get FREE 30 Million BABYDOGE, When someone register an account & verify KYC Document with your referral code & Get FREE 50 Million BABYDOGE their First Investment.',
    },
   
]

    const [dataBlock] = useState(
        {
            heading: '3 Types Airdrop Bonus',
            desc : 'Cetoex has a variety of features that make it the best place to start trading'
        }
    )
    return (
        <section className="trading">
            <div className="container">
                <div className="row">
                <div className="col-xl-6 col-md-12">
                    <div className="trading__image">
                    <img src={img} alt="referral_program" style={{width:"100%",height:"100%"}} />
                    </div>
                </div>
                <div className="col-xl-6 col-md-12">
                    <div
                    className="trading__content"
                    >
                    <h3 className="heading">{dataBlock.heading}</h3>
                   
                    <ul className="list-steps">
                        {
                            data.map(idx => (
                                <li key={idx.id}>
                                    <img src={idx.icon} alt="Cetoex" />
                                    <div className="content">
                                        <p className="step">{idx.step}</p>
                                        <h6 className="title">{idx.title}</h6>
                                        <p className="fs-16">
                                            {idx.text}
                                        </p>
                                    </div>
                                </li>
                            ))
                        }
                        
                    </ul>
                    </div>
                </div>
                </div>
            </div>
        </section>
    );
}

export default Trading;