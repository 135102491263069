import React , {useEffect, useState} from 'react';
import img1 from '../../assets/images/layout/airdrop.png'
import './banner.scss'

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';


function Banner05(props) {

    useEffect(()=>{
        // The End Of Year Date To Countdown To

let countDownDate = new Date("Mar 1, 2025 23:59:59").getTime();

let counter = setInterval(() => {
  // Get Date Now
  let dateNow = new Date().getTime();

  // Find The Difference Between The Time Now And The Countdown Date
  let dateDiff = countDownDate - dateNow;

  let days = Math.floor(dateDiff / (1000 * 60 * 60 * 24));

  let hours = Math.floor((dateDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

  let minutes = Math.floor((dateDiff % (1000 * 60 * 60)) / (1000 * 60));

  let seconds = Math.floor((dateDiff % (1000 * 60)) / 1000);

  document.querySelector(".days").innerHTML =
    days < 100 && days > 10 ? `0${days}` : days < 10 ? `00${days}` : days;
  document.querySelector(".hours").innerHTML = hours < 10 ? `0${hours}` : hours;
  document.querySelector(".minutes").innerHTML =
    minutes < 10 ? `0${minutes}` : minutes;
  document.querySelector(".seconds").innerHTML =
    seconds < 10 ? `0${seconds}` : seconds;

  if (dateDiff <= 0) {
    clearInterval(counter);
  }
}, 1000);

    });
  
    const [dataBlock] = useState(
        {
            title: 'Crypto Airdrop',
            desc : 'CetoEX Airdrops provide an opportunity to receive free tokens without any direct investment. Participants can accumulate tokens without spending their own money, which can be beneficial, especially if the airdropped tokens gain value in the future. n some cases, airdropped tokens may provide additional benefits to token holders, such as discounts on platform fees, access to exclusive features, or voting rights in governance decisions. These added benefits can enhance the value and utility of the tokens received through the airdrop.',
            title2: 'COMMING IN'
        }
    );
    return (
        <section className="banner">
                <div className="container">
                    <div className="row">
                    <div className="col-xl-6 col-md-12">
                        <div className="banner__content">
                        <h2 className="title">{dataBlock.title}</h2>
                        <p className="fs-20 desc">
                            {dataBlock.desc}
                        </p>
                       
                       
                        <div className="partner">
                        <h6>{dataBlock.title2}</h6>
                            <div className="partner__list">

                            <div className="containerTimer">
  <div>
    DAYS
    <span className="days" />
  </div>
  <div>
    HRS
    <span className="hours" />
  </div>
  <div>
    MIN
    <span className="minutes" />
  </div>
  <div>
    SEC
    <span className="seconds" />
  </div>
</div>
                 
                            </div>
                        </div>
                       
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-12">
                        <div className="banner__image">
                        <img src={img1} alt="airdrop"  style={{width:"80%"}} />
                        </div>
                    </div>
                    </div>
                </div>
            </section>
    );
}

export default Banner05;