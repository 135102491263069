import './style.scss';
import {React,useEffect,useState} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Bars } from  'react-loader-spinner'





function Orderbook(Props) {
  
  const[state,setState] = useState('BTC');
  const [buyList, setBuyList] = useState({});
  const [sellList, setSellList] = useState({});


  useEffect(() => {
    const socket = new WebSocket(`wss://websocket.cetoex.com:8080/orderbook?coinName=${Props.data}`);
  
    socket.onopen = () => {
      console.log('WebSocket connection established.');
    };
  
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
  
      // Function to group by order_price and sum order_amount
      const groupAndSumOrders = (orders) => {
        return Object.values(orders.reduce((acc, order) => {
          if (!acc[order.order_price]) {
            acc[order.order_price] = { ...order, order_amount: 0 };
          }
          acc[order.order_price].order_amount += order.order_amount;
          return acc;
        }, {}));
      };
  
      // Filter buy and sell orders, group by order_price, and sum order_amount
      const buyOrders = groupAndSumOrders(
        data.filter(order => order.order_side.toLowerCase().includes('buy'))
      ).sort((a, b) => b.order_price - a.order_price); // Ascending order by price
  
      const sellOrders = groupAndSumOrders(
        data.filter(order => order.order_side.toLowerCase().includes('sell'))
      ).sort((a, b) => a.order_price - b.order_price); // Descending order by price
  
      setBuyList(buyOrders);
      setSellList(sellOrders);
    };
  
    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  
    socket.onclose = () => {
      console.log('WebSocket connection closed.');
    };
  
    // Cleanup function
    return () => {
      socket.close();
    };
  }, [Props.data]);
  
  



    const timestamp = (timestamp)=>{

      let timestampString = timestamp.toString();
      let splitDate = timestampString.substring(0, 10);
      let splitTime = timestampString.substring(11, 19);
    
       return splitTime+' '+splitDate;
  
      //   let unix_timestamp = new Date(timestamp).valueOf();
       
      //   unix_timestamp = unix_timestamp.toString();
      //   unix_timestamp = unix_timestamp.slice(0, -3);
      //  return unix_timestamp = parseInt(unix_timestamp);
  
      }



    function scientificToDecimal(num) {
      const str = num.toString().toLowerCase();
      if (str.indexOf('e') === -1) {
        return str;
      }
      const parts = str.split('e');
      const coefficient = parseFloat(parts[0]);
      const exponent = parseInt(parts[1]);
      const decimalPart = coefficient.toFixed(4).replace('.', '');
      const exponentPart = Math.abs(exponent).toString();
      return (
          <>
          0.0<sub>{exponentPart-1}</sub>{decimalPart}
          </>
      )
    }
  
 

  return (
    <>
     <div className="trade_pair"><center>
                  
                       </center>
                       <div className="flat-tabs">
                       <div className="orderbook">Orderbook</div>
  <div className="table-main">   
  <Tabs defaultIndex={0} >
    <TabList>
      <Tab>
      <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  fill="none"
  className="css-3kwgah"
  style={{ width: 25 }}
>
  <path d="M4 4h7v7H4V4z" fill="#5cb85b" />
  <path d="M4 13h7v7H4v-7z" fill="#d33535" />
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z"
    fill="grey"
  />
</svg>


      </Tab>
      <Tab>
      <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  fill="none"
  className="css-3kwgah"
  style={{ width: 25 }}
>
  <path d="M4 4h7v16H4V4z" fill="#5cb85b" />
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z"
    fill="grey"
  />
</svg>

     
      </Tab>
      <Tab>
           
      <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  fill="none"
  className="css-3kwgah"
  style={{ width: 25 }}
>
  <path d="M4 4h7v16H4V4z" fill="#d33535" />
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z"
    fill="grey"
  />
</svg>

    

      </Tab>
    </TabList>

    <TabPanel>


    <div className="table-main">   
       
       <table className="table-scroll1" style={{height:"220px",overflow:"hidden"}}>
        <thead>
           <tr>
          
           <th>Bid Time</th>
           <th>Bid Price</th>
           <th>Amount<sub>({Props.data})</sub></th>
        
           </tr>
         </thead>
         <tbody className="body-half-screen">
           
         {
                buyList && buyList.length>0 ?
                buyList.map((idx, i) =>
                    
                <tr>
           
             <td style={{color:"var(--tablemain)"}}><p  style={{marginLeft:"5px"}}>{timestamp(idx.order_timestamp)}</p></td>
           
            <td style={{color:"var(--tablemain)"}}>{scientificToDecimal(idx.order_price)}</td>
            <td className="up">{idx.order_amount}</td>
           
              </tr>
                )
                : buyList && buyList.length === 0 ? (
                  <div style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '62px' }}>
                   <center> <p className="fa fa-file" style={{fontSize:"35px",color:"#5cb85b",paddingBottom:"10px"}}></p>
                    <h6  className="no-datatext">Data Unavailable</h6></center>
                  </div>
                )
                :(
                  <>
                 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '72px' }}>
             <Bars
    height = "40"
    width = "40"
    radius = "9"
    color = '#d33535'
    ariaLabel = 'three-dots-loading'  
    marginLeft='200'   
    wrapperStyle
    wrapperClass
  />
  </div>

                  </>
                ) 
                 }
                 

          
         </tbody>
       </table>
        
       </div>




    <div className="table-main">   
       
       <table className="table-scroll1" style={{height:"210px",overflow:"hidden"}}>
        <thead>
           <tr>
          
           <th>Ask Time</th>
           <th>Ask Price</th>
           <th>Amount<sub>({Props.data})</sub></th>
        
           </tr>
         </thead>
         <tbody className="body-half-screen">
           
         {
                sellList && sellList.length>0 ?
                sellList.map((idx, i) =>
                    
                <tr>
           
             <td style={{color:"var(--tablemain)"}}><p  style={{marginLeft:"5px"}}>{timestamp(idx.order_timestamp)}</p></td>
           
            <td style={{color:"var(--tablemain)"}}>{scientificToDecimal(idx.order_price)}</td>
            <td  className="down">{idx.order_amount}</td>
           
              </tr>
                ): sellList && sellList.length === 0 ? (
                  <div style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '62px' }}>
                   <center> <p className="fa fa-file" style={{fontSize:"35px",color:"#d33535",paddingBottom:"10px"}}></p>
                    <h6  className="no-datatext">Data Unavailable</h6></center>
                  </div>
                ) :(
                  <>
                 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '72px' }}>
             <Bars
    height = "40"
    width = "40"
    radius = "9"
    color = '#5cb85b'
    ariaLabel = 'three-dots-loading'  
    marginLeft='200'   
    wrapperStyle
    wrapperClass
  />
  </div>

                  </>
                ) 
                 }
                 

          
         </tbody>
       </table>
        
       </div>


       
    </TabPanel>
    <TabPanel>
    <div className="table-main">   
       
       <table className="table-scroll" style={{height:"430px",overflow:"hidden"}}>
        <thead>
           <tr>
          
           <th>Bid Time</th>
           <th>Bid Price</th>
           <th>Amount<sub>({Props.data})</sub></th>
        
           </tr>
         </thead>
         <tbody className="body-half-screen">
           
         {
                buyList && buyList.length>0 ?
                buyList.map((idx, i) =>
                    
                <tr>
           
             <td style={{color:"var(--tablemain)"}}><p  style={{marginLeft:"5px"}}>{timestamp(idx.order_timestamp)}</p></td>
           
            <td style={{color:"var(--tablemain)"}}>{scientificToDecimal(idx.order_price)}</td>
            <td  className="up">{idx.order_amount}</td>
           
              </tr>
                ): buyList && buyList.length === 0 ? (
                  <div style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '162px' }}>
                   <center> <p className="fa fa-file" style={{fontSize:"35px",color:"#5cb85b",paddingBottom:"10px"}}></p>
                    <h6  className="no-datatext">Data Unavailable</h6></center>
                  </div>
                ):(
                  <>
                 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '180px' }}>
             <Bars
    height = "40"
    width = "40"
    radius = "9"
    color = '#5cb85b'
    ariaLabel = 'three-dots-loading'  
    marginLeft='200'   
    wrapperStyle
    wrapperClass
  />
  </div>

                  </>
                ) 
                 }
                 

          
         </tbody>
       </table>
        
       </div>

    </TabPanel>
    <TabPanel>
       
       
    <div className="table-main">   
       
       <table className="table-scroll"  style={{height:"430px",overflow:"hidden"}}>
        <thead>
           <tr>
          
           <th>Ask Time</th>
           <th>Ask Price</th>
           <th>Amount<sub>({Props.data})</sub></th>
        
           </tr>
         </thead>
         <tbody className="body-half-screen">
           
         {
                sellList && sellList.length>0 ?
                sellList.map((idx, i) =>
                    
                <tr>
           
             <td style={{color:"var(--tablemain)"}}><p  style={{marginLeft:"5px"}}>{timestamp(idx.order_timestamp)}</p></td>
           
            <td style={{color:"var(--tablemain)"}}>{scientificToDecimal(idx.order_price)}</td>
            <td  className="down">{idx.order_amount}</td>
           
              </tr>
                ): buyList && buyList.length === 0 ? (
                  <div style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '162px' }}>
                   <center> <p className="fa fa-file" style={{fontSize:"35px",color:"#d33535",paddingBottom:"10px"}}></p>
                    <h6  className="no-datatext">Data Unavailable</h6></center>
                  </div>
                ):(
                  <>
                 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '180px' }}>
             <Bars
    height = "40"
    width = "40"
    radius = "9"
    color = '#d33535'
    ariaLabel = 'three-dots-loading'  
    marginLeft='200'   
    wrapperStyle
    wrapperClass
  />
  </div>

                  </>
                )  
                 }
                 

          
         </tbody>
       </table>
        
       </div>
    </TabPanel>
    </Tabs>
                   
 
</div>
  
                       </div>
                     </div>
    </>
  );
}

export default Orderbook;
